html,body{padding: 0;margin:0;}
.container{
  clear: both;
  width:1200px;
  margin: 0 auto;
  box-sizing: border-box;
  @media #{$xlarge-screen} {}
  @media #{$large-screen} {  width:1100px;}
  @media #{$xmedium-screen} {width:900px;}
  @media #{$medium-screen} {width:100%;padding:0 20px;}
  @media #{$small-screen} {width:100%;}
  @media #{$xssmall-screen} {width:100%;}
}

$screen-sizes: ('xl','lg','xm','md','sm','xs');
$number-of-columns: 12;
$screen-sizes-small-first: reverse($screen-sizes);

@each $item in $screen-sizes-small-first{
  @if $item == "xl"{
    @media #{$xlarge-screen} {
      .hidden-#{$item}{
        display: none;
      }
    }
  }

  @if $item == "lg"{
    @media #{$large-screen} {
      .hidden-#{$item}{
        display: none;
      }
    }
  }
  @if $item == "xm"{
    @media #{$xmedium-screen} {
      .hidden-#{$item}{
        display: none;
      }
    }
  }
  @if $item == "md"{
    @media #{$medium-screen} {
      .hidden-#{$item}{
        display: none;
      }
    }
  }

  @if $item == "sm"{
    @media #{$small-screen} {
      .hidden-#{$item}{
        display: none;
      }
    }
  }
  @if $item == "xs"{
    @media #{$xssmall-screen} {
      .hidden-#{$item}{
        display: none;
      }
    }
  }
}

@each $item in $screen-sizes-small-first{
  @for $i from 0 through $number-of-columns
  {
    .col-#{$item}-#{$i}{
      width: percentage($i/$number-of-columns);
    }

    .col-#{$item}-offset-#{$i}{
      margin-left: percentage($i/$number-of-columns);
    }
  }
}

$selector: ();

@each $item in $screen-sizes {
  @for $i from 1 through $number-of-columns
  {
    $selector: append($selector, unquote('.col-#{$item}-#{$i}'), 'comma');
  }

  @if($item == "xl")
  {
    @media #{$xlarge-screen} {
      @for $i from 0 through $number-of-columns
      {
        .col-#{$item}-#{$i}{
          width: ($i/$number-of-columns * 100) *1%;
        }

        .col-#{$item}-offset-#{$i}{
          margin-left: percentage($i/$number-of-columns);
        }
      }
    }
  }
  @else if $item == "lg"{
    @media #{$large-screen} {
      @for $i from 0 through $number-of-columns
      {
        .col-#{$item}-#{$i}{
          width: ($i/$number-of-columns * 100) *1%;
        }

        .col-#{$item}-offset-#{$i}{
          margin-left: percentage($i/$number-of-columns);
        }
      }
    }
  }
  @else if $item == "xm" {
    @media #{$xmedium-screen} {
      @for $i from 0 through $number-of-columns
      {
        .col-#{$item}-#{$i}{
          width: ($i/$number-of-columns * 100) *1%;
        }

        .col-#{$item}-offset-#{$i}{
          margin-left: percentage($i/$number-of-columns);
        }
      }
    }
  }
  @else if $item == "md" {
    @media #{$medium-screen} {
      @for $i from 0 through $number-of-columns
      {
        .col-#{$item}-#{$i}{
          width: ($i/$number-of-columns * 100) *1%;
        }

        .col-#{$item}-offset-#{$i}{
          margin-left: percentage($i/$number-of-columns);
        }
      }
    }
  }
  @else if $item == "sm" {
    @media #{$small-screen} {
      @for $i from 0 through $number-of-columns
      {
        .col-#{$item}-#{$i}{
          width: ($i/$number-of-columns * 100) *1%;
        }

        .col-#{$item}-offset-#{$i}{
          margin-left: percentage($i/$number-of-columns);
        }
      }
    }
  }
  @else if $item == "xs" {
    @media #{$xssmall-screen} {
      @for $i from 0 through $number-of-columns
      {
        .col-#{$item}-#{$i}{
          width: ($i/$number-of-columns * 100) *1%;
        }

        .col-#{$item}-offset-#{$i}{
          margin-left: percentage($i/$number-of-columns);
        }
      }
    }
  }
}

#{$selector} {
  float: left;
  padding-left:10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.row{
  margin-right: -10px;
  margin-left: -10px;
}

.pull-right{
  float: right;
}

.pull-left{
  float: left;
}
