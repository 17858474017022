$buckeye-green: (
        "base" :#71a038,
        "dark" :#426616
);
$buckeye-blue: (
        "base" : #193249
);

$custom-colors: (
        "buckeye-green" : $buckeye-green,
        "buckeye-blue" : $buckeye-blue
);
