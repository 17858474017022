.hero{
  width:100%;

  @media #{$xlarge-screen}{}
  @media #{$large-screen}{}
  @media #{$xmedium-screen}{}
  @media #{$medium-screen}{}
  @media #{$small-screen}{}
  @media #{$xssmall-screen}{
    height:400px !important;}
}