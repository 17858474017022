.product-image{
  position: relative;

  h2{
    position: absolute;
    font-size: 30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    margin: 0;
    }
  img{
    padding: 0 0 1rem 0;

  }
}

.sweet-logo{
  position: absolute;
  bottom: 5px;
  left: 15px;
}

.product-text{
  h3{
    font-size: 30px;
    margin: 0 0 10px 0;
    font-weight: bold;
    }
  p{
    margin-left: 0;
    font-size: 20px;
  }
  ul{
    line-height: 1.5rem;
  }
}

.other-supplies{
  h2{
    font-size: 20px;
  }
}

.product-rule {
  display: block;
  margin: 30px 0 50px 0;
  outline: 0;
  border-bottom: dashed 1px color('gray','lighten-1');
}

.recommend-title{
  background: color('buckeye-green','dark');
  border-radius:  10px 10px 0 0;
  @media #{$small-screen}{
    margin-top: 20px;
  }
  img{
    margin: 0 30px;
  }
  span{
    display: block;
    font-size: 1.71rem;
    text-transform: uppercase;
    color: color('shade','white');
    padding-top: 10px;
    padding-bottom: 10px;
    @media #{$medium-screen} {  font-size:1.5rem;}
    @media #{$small-screen}{font-size: 1rem;}
  }
}
.recommend{
  background: color('buckeye-green','base');
  color: white;
  border-radius: 0 0 10px 10px;

  h4{
    text-transform: uppercase;
    font-size: 1.5rem;
    color: color('shade','white');
    margin-top: 0;
    margin-bottom: 10px;
    @media #{$medium-screen}{
      margin-top: 30px;
    }
  }
  h5{
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0.5em 0;
    @media #{$small-screen}{
      padding-left: 0;
      padding-right: 0;
    }
  }
  hr{
    height: 1px;
  }
  span{
    text-transform: uppercase;
    color: color('buckeye-green','dark');
    @media #{$small-screen}{
      padding-left: 0;
    }
  }

  img{
  padding-top: 0;
  }

  ul{
    line-height: 1.8rem;
    margin-top: 5px;
    padding-left: 20px;
  }

}
