button{
  border-radius: 10px;
  height: 44px;
  background-color: color("buckeye-green","base");
  color: color("shade","white")
}
a.btn{
  border-radius: 10px;
  display: block;
  background-color: color("buckeye-green","base");
  color: color("shade","white");
  text-align:center;
}
a.btn--padding-lr-3{
	padding-left: 3.0em;
	padding-right: 3.0em;
}

button,a.btn{
  &.inverse{
    background-color: color('shade','white');
    border: solid 2px color('buckeye-green','base');
    color: color('buckeye-green','base');
  }
}
input[type="text"],input[type="password"],input[type="search"], select,textarea,.output-box {
  outline:0 !important;
  border: 1px solid #e0e0e0 !important;
}