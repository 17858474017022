@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Noto+Serif);

html{
  font-family: 'Montserrat', sans-serif;
  font-size:14px;
  font-weight: 400;

  @media #{$medium-screen}{font-size:16px;}
}

h1{
  text-transform: uppercase;
  font-size: 32px;
  text-align: center;
  margin: 0;
  padding: 0;

}

h2{
  font-weight: normal;
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 40px;
}

h3{
  text-transform: uppercase;
  font-size: 32px;
  margin: 0;
  padding: 0;
}

h4{
  font-weight: normal;
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 40px;
}

h5{

}

h6{
  padding: 0;
  margin:0;
}

p{
  line-height: 1.5rem;
  display: block;
  margin: 0 auto;
}

.italic{
  font-family: 'Noto Serif', serif;
  font-style: italic;
}
a{
  text-decoration: none;
  color: inherit;
  transition: color 0.4s ease, background 0.4s ease;

  &:visited,&.hover,&:focus,&.active{
    color: color("buckeye-green","base");
  }
}
