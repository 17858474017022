section{
  padding: 5rem 0;

  @media #{$small-screen}{
    padding: 4rem 0;
  }
}

.no-float{
  float: none;
}

.truncate{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

hr{
  background-color: color('shade','white');
  border: none;
  height: 4px;
}

.vert-rule{
  border-right: solid 3px;
}

img{
  padding: 1rem 0;
}

.trans-box{
  background: rgba(0, 0, 0, 0.5);
  padding:20px;
}

.small-pad-top{
  padding-top: 1rem;
}

.small-pad-bottom{
  padding-bottom: 1rem;
}

.small-pad-both{
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.medium-pad-top{
  padding-top: 2rem;
}

.medium-pad-bottom{
  padding-bottom: 2rem;
}

.flex{
  display: flex;
  &.vert-center{
    align-items: center;
  }
  &.hori-center{
    justify-content: center;
  }


  &-wrap{
    flex-wrap: wrap;
  }

  &-vert-center{
    align-items: center;
  }
}

.center-middle{
  margin:auto;
}

a{
  text-decoration: none;

  &:hover{
    color: color('buckeye-green','base')
  }
}

.drop-shadow{
  text-shadow: 0 0 64px #000;
}

.box{
  padding:25px;

  &.output{
    display: none;
    border: 1px solid color("buckeye-green","base");
    margin-top: 50px;
    p{
      text-transform: uppercase;
    }

    &.white{
      color: color("shade","white");
      border: 1px solid color("shade","white");
    }
  }
}

.center-col{
  display: block;
  float: none;
  margin: 0 auto;
}

.text-2-col{
  -webkit-column-count: 2;
     -moz-column-count: 2;
          column-count: 2;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
  @media #{$small-screen}{
    -webkit-column-count: 1;
       -moz-column-count: 1;
            column-count: 1;
  }

}

.line{
  display: inline-block;

}
