
.intro{
  padding-top: 50px;
  padding-bottom: 50px;
  background-image: url('/img/concrete_seamless.png');

  h5{
    font-size: 25px;
      @media #{$medium-screen}{
        font-size: 20px;
      }
      @media #{$xssmall-screen}{
        text-align: center;
      }
    }

  .intro-text p{
    margin:0;
  }

  img{
    padding:0;
    border-radius: 50%;
  }
}


.home-products{
  background-image: url('/img/concrete_seamless.png');
  padding: 30px 0 0 0;
}

.category{
  display: block;
  max-width: 300px;
  margin: 20px auto;
  border-radius: 100%;
  transition: box-shadow 0.4s ease-in-out;
  position: relative;

  &:hover{
    box-sizing: inherit;
    border-radius: 50%;
    padding: 0;
    box-shadow: inset 0 0 0 150px rgba(0,0,0,0.5), 0 1px 2px rgba(0,0,0,0.1);

    img{
      opacity:0.1;
    }
  }

  img{
    border-radius: 50%;
    padding:0;
    opacity:1;
    border: 15px solid color("gray","lighten-5");
    transition: visbility 1s, opacity 0.5s;
  }
  a{
    &:visited{color: color('shade','white');}
    &:link {color:color("shade","white");}
    &:hover {color:color("shade","white");}
    &:active {color:color("shade","white");}
  }
  .title{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 5px;
    margin: 0;
    font-size: 18px;
    font-weight: normal;
  }
}

.home-newsletter{
  background-image: url('/img/newsletter-bg.jpg');
  background-size: cover;
  background-attachment: fixed;
  img{
    margin: 0 10px 0 0;
    padding: 0;
  }
}

.phone-bar {
  padding: 10px;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  line-height: 25px;
  .container{
    >span:nth-child(1){
      font-size: 1.2rem;
    }
    >span:nth-child(1):after,span:nth-child(2):after{
      content: '|';
      font-size: 1rem;
      padding: 0 10px;
    }
    @media #{$small-screen}{
      padding:0;

      >span:nth-child(1){
        display: block;
        font-size: 1.15rem;
      }
      >span:nth-child(2),span:nth-child(3){
        font-size: 0.7rem;
      }
      >span:nth-child(1):after{
        content: '';
      }
      >span:nth-child(1):after,span:nth-child(2):after{
        padding: 0 5px;
      }
    }
  }

  a{
    &:visited{color: color('shade','white');}
    &:link {color:color("shade","white");}
    &:hover {color:color("shade","white");}
    &:active {color:color("shade","white");}
  }

  &.sticky {
    position: fixed;
    top:0;
    -webkit-transform: translate3d(0,0,0); /* Safari */
    transform: translate3d(0,0,0);
    width: 100%;
    z-index: 1000;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.18);
  }
}

.calculator{
  background: color('buckeye-green','base');

  .multiplier{
    position: relative;
    &:after{
      position: absolute;
      content: 'x';
      color: color("buckeye-green","dark");
      top: 17px;
      right: -14px;

      @media #{$xmedium-screen} {
        top: 18px;
        right: -15px;
      }
      @media #{$xssmall-screen} {
        display: none;
      }
    }
  }
  .reset{
    color: color('shade','white');
    font-family: sans-serif;
    font-weight: lighter;
    text-decoration: underline;
    text-align: center;
  }
}

.about-us {
  background-image: url('/img/about-bg.jpg');
  background-size: cover;
  @media #{$xssmall-screen}{
    background-image: none;
    background: color('buckeye-blue','base');
  }

  img {
    padding: 0;
    box-sizing: border-box;
    border-radius: 50%;
    border: solid 3px;
    @media #{$small-screen}{
      margin-bottom: 20px;
    }
  }

  p {
    line-height: 1.5rem;
    margin-bottom: 10px;
    max-width: none;
  }
}
  .delivery-options {
    background-image: url('/img/concrete_seamless.png');
    img {
      display: block;
      margin: 0 auto;
    }
  }

  .delivery-rates {
    padding-top: 30px;
  }

  .call {
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 20px;
    p{
      line-height: 1.25em;
    }
    a {
      &:visited {
        color: color('shade', 'black');
      }
      &:link {
        color: color('shade', 'black');
      }
      &:hover {
        color: color('shade', 'black');
      }
      &:active {
        color: color('shade', 'black');
      }
    }
  }

  .contact {
    background-image: url('/img/delivery-rates-bg.jpg');
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    a{
      &:visited{color: inherit;}
      &:link {color:inherit;}
      &:hover {color:inherit;}
      &:active {color:inherit;}
    }

    .call-us{
      font-size: 32px;
    }

  }

  .emergency {
    background-image: url('/img/emergency-bg.png');
    background-size: cover;
    padding: 27px;
    outline: solid 2px color('shade', 'white');
    outline-offset: -10px;

    img {
      margin-left: 10px;
    }

    @media #{$small-screen}{
      text-align: center;
      & .vert-rule {
        border: none;
      }
    }
    p{
      font-size: 1.2rem;
    }

    a {
      @media #{$small-screen}{
        font-size: 14px;
      }
    }
  }

  #map {
    height: 300px;
    width: 100%;
    background-image: url("/img/google-map.jpg");
    background-position: center;
  }
