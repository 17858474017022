*{
  box-sizing: border-box;
}
.left{
  float:left;
}
.right{
  float:right;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.block{
  display: block;
  &.center{
    margin:  0 auto;
    &.constrain{
      max-width: 600px;
    }
  }
}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */

.img-responsive{
  max-width:100%;
  margin:0 auto;
}

@function reverse($list, $recursive: false) {
  $result: ();

  @for $i from length($list)*-1 through -1 {
    @if type-of(nth($list, abs($i))) == list and $recursive {
      $result: append($result, reverse(nth($list, abs($i)), $recursive));
    }

    @else {
      $result: append($result, nth($list, abs($i)));
    }
  }

  @return $result;
}
