footer{
  padding:5em 0;
}
.location {
  background-image: url('/img/grass-white-bottom-bg.png');
  background-repeat: repeat-x;
  background-size: 400px;
  background-position: bottom;
}

.callout{
  font-size: 32px;
  text-transform: uppercase;

  & + p{
    font-weight: normal;
    margin-top: 0;
    font-size: 18px;
    margin-bottom: 40px;
  }
}

.version {
  color: rgba(255, 255, 255, 0.2 );
  padding: 20px 0 0 0;
  font-size: 10px;
}
