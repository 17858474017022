.hero{
  height:600px;
  background-image: -moz-linear-gradient(top,  rgba(0,0,0,0.50) 0%, rgba(94,139,174,0) 75%, rgba(125,185,232,0) 100%), url('/img/hero-temp2.jpg');
  background-image: -webkit-linear-gradient(top,  rgba(0,0,0,0.50) 0%,rgba(94,139,174,0) 75%,rgba(125,185,232,0) 100%),url('/img/hero-temp2.jpg');
  background-image: linear-gradient(to bottom,  rgba(0,0,0,0.50) 0%,rgba(94,139,174,0) 75%,rgba(125,185,232,0) 100%),url('/img/hero-temp2.jpg');

  background-size: 2552px auto;
  background-position: center center;
  background-position-y: -150px;
  background-attachment: fixed;
  background-repeat: no-repeat;
  align-items: center;

  @media #{$xssmall-screen} {
    background-position-y: 0;
    background-size: 1200px auto;
    background-image: -moz-linear-gradient(top,  rgba(0,0,0,0.50) 0%, rgba(94,139,174,0) 75%, rgba(125,185,232,0) 100%), url('/img/hero-temp2.jpg');
    background-image: -webkit-linear-gradient(top,  rgba(0,0,0,0.50) 0%,rgba(94,139,174,0) 75%,rgba(125,185,232,0) 100%),url('/img/hero-temp2.jpg');
    background-image: linear-gradient(to bottom,  rgba(0,0,0,0.50) 0%,rgba(94,139,174,0) 75%,rgba(125,185,232,0) 100%),url('/img/hero-temp2.jpg');
  }

  h1{
    font-size: 60px;
    margin-bottom: 10px;

    @media #{$large-screen} {
      font-size: 50px;
    }

    @media #{$xmedium-screen} {
      font-size: 40px;
    }

    @media #{$small-screen}{
      font-size: 20px;
    }
  }
  h2{
    margin-bottom: 0;
    font-size: 18px;
    @media #{$small-screen}{
      font-size: 18px;
    }
  }

  hr{
    height: 2px;
  }
  @media #{$small-screen}{


  }
}

#limestone-hero{
  background-image: -moz-linear-gradient(top,  rgba(0,0,0,0.50) 0%, rgba(94,139,174,0) 75%, rgba(125,185,232,0) 100%), url('/img/limestone-hero6.jpg');
  background-image: -webkit-linear-gradient(top,  rgba(0,0,0,0.50) 0%,rgba(94,139,174,0) 75%,rgba(125,185,232,0) 100%),url('/img/limestone-hero6.jpg');
  background-image: linear-gradient(to bottom,  rgba(0,0,0,0.50) 0%,rgba(94,139,174,0) 75%,rgba(125,185,232,0) 100%),url('/img/limestone-hero6.jpg');
}
#topsoil-hero{
  background-image: url('/img/gradient-top-overlay.png'), url('/img/topsoil-background.jpg');
}
#gravel-hero{
  background-image: url('/img/gradient-top-overlay.png'), url('/img/gravel-hero.jpg');
}
#mulch-hero{
  background-image: url('/img/gradient-top-overlay.png'), url('/img/mulch-hero-dark.jpg');
}
#additional-supplies-hero{
  background-image: url('/img/gradient-top-overlay.png'), url('/img/additional-supplies-background.jpg');
}
#building-supplies-hero{
  background-image: url('/img/gradient-top-overlay.png'), url('/img/building-supplies-background.jpg');
}
#firewood-hero{
  background-image: url('/img/gradient-top-overlay.png'), url('/img/firewood-background.jpg');
}
#mason-sand-hero{
  background-image: url('/img/gradient-top-overlay.png'), url('/img/mason-sand-background.jpg');
}
