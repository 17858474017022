header{
  position: absolute;
  width: 100%;
}
#nav-toggle,.navbar{
  z-index: 101;
}
#nav-overlay.mask{
  background-color: rgba(0,0,0,0.4);
  position: absolute;
  width:100%;
  height:100vh;
  z-index: 100;
}

.navbar{
  @media #{$medium-screen}{
    visibility: hidden;
    transform: translateX(-241px);
  }
  &.expanded {
    visibility: visible;
    background: rgba(255,255,255,0.98);
    position: fixed;
    bottom: 0;
    top: 0;
    width: 240px;
    overflow-x: hidden;
    overflow-y: auto;
    transform: translateX(0);
    transition: transform 0.5s, visibility 0.5s;

    a{
      width: 100%;
      color: color("shade","black");
      padding:5px 15px;
      border:1px solid color("gray","lighten-4");
      &:hover, &.active{
        background: color("buckeye-green","base");
        color: color("shade","white");
      }
    }
    ul,li{
      display: block;
      margin: 0;
      padding: 0;
      background: none;
    }
    ul li{
      line-height: 2.5rem;
    }
    ul.dropdown{
      padding:0;
      position: static;
      background: transparent;
      li a{
        padding-left: 40px;
        &:before{
          content: '\f105';
          margin-right: 10px;
          font-family: FontAwesome;
        }
      }
    }
  }
}


nav{
  text-transform: uppercase;
  position: relative;
  overflow: visible !important;
  height:50px;

  @media #{$xmedium-screen} {
    .container{
      width:950px;
    }
  }
  @media #{$medium-screen} {
    .container{
      width:100%;
    }
  }


  .logo-container{
    position: relative;
    @media #{$medium-screen}{
      max-width: 230px;
    }
    .logo{
      width: 75px;
      @media #{$medium-screen} {
        margin:0 15px;
      }

      @media #{$xssmall-screen} {
        width:50px;
        margin: 15px;
        padding: 0;
      }
    }
    span.company-name{
      @media #{$xssmall-screen} {
        font-size: 14px;
        left:75px;
      }
      color: color("shade","white");
      font-size: 20px;
      font-weight: normal;
      text-align: left;
      white-space: pre;
      position: absolute;
      top:26px;
      left:105px;
    }
  }

  ul{
    margin:0;
    padding:30px 0 0 0;
    list-style:none;
    display:flex;
    a{
      color: color("shade","white");
      padding:10px 20px;
      box-sizing: border-box;
      display: block;
      &:visited,&:focus{ color: color("shade","white");}
      &:hover{
        color: color("buckeye-green","base");}
    }
  }
  ul li{
    display: inline-block;
    padding:0;
    &:hover{
      background: rgba(0,0,0,0.7);
      ul.dropdown{
        display: block;
      }

    }
  }
  ul.dropdown{  display: none;padding:0;}
  ul.dropdown li{  display: block;padding:0; background: none}
}

.link-container{
  @media #{$small-screen}{
    display: none;
  }
}

.scroll-top{
  font-size: 47px;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
}
