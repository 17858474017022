/*****************************************
*
*
*
* Table of Contents
* 1. Breakpoints
* 2.
* 3.
* 4.
* 5.
* 6. Admin
*
*******************************************/

/******************************************
*     1. Breakpoints                      *
*******************************************/

// 42connect's Internal Grid Design Pattern
// ***************** Design Pattern *****************
// Our designers design at specific sizes... the goal here is to make three separate designs that
// share the same user experience. We don't want users to be alienated if they view the site
// on mobile/tablet/desktop. We have chosen the breakpoints such that the number of pixels
// allocated to a screen-size is relative to the expected amount of views at that screen
// size. For small screens, we chose the middle of the range 320px - 480px, because
// often times, 480px designs become cramped when reduced down by 160px*
//
// Large Screen - 1440px
// Medium Screen - 768px
// Small Screen - 375px
//
// ***************** CSS Breakpoint pattern *****************
// As developers, we know that designs break...it happens...text widows, breaks lines, and
// will look bad at "in-between zones". Our approach to solving this problem is to
// provide enough breakpoints so that we can prevent these as best as possible.
// Our CSS breakpoints to attempt to provide a solution for this are:
//
// XL Screen - 1441px - 1920px - Large Screen Design
// LG Screen - 1200px - 1440px - Large Screen Design
// XM Screen - 1024px - 1199px - Large Screen Design
// MD Screen - 768px - 1023px - Medium Screen Design
// SM Screen - 600px - 767px - Medium Screen Design
// XS Screen - 320px - 600px - Small Screen Design
//
// We don't handle screen sizes over 1920px generally, unless background images need to be handled
// We also don't handle screen sizes under 320px as these screens are not generally found in the wild

$xssmall-screen: "only screen and (max-width : 600px)";
$small-screen-and-up: "only screen and (min-width : 600px)";/*Use Sparingly*/
$small-screen:"only screen and (max-width : 767px)";
$medium-screen-and-up: "only screen and (min-width : 768px)";/*Use Sparingly*/
$medium-screen:"only screen and (max-width : 1023px)";
$xmedium-screen:"only screen and (max-width : 1200px)";
$large-screen:"only screen and (max-width : 1440px)";
$xlarge-screen:"only screen and (max-width : 1920px)";
$xlarge-screen-and-up:"only screen and (min-width : 1921px)";


/**
  @media #{$xlarge-screen}{}
  @media #{$large-screen}{}
  @media #{$xmedium-screen}{}
  @media #{$medium-screen}{}
  @media #{$small-screen}{}
  @media #{$xssmall-screen}{}
*/

/******************************************
*     6. Admin                            *
*******************************************/
//Global
$admin-background-color: color("blue","base");
$admin-margin: 20px;
$admin-border: 1px solid color("gray","lighten-3");
//Nav
$admin-nav-height: 60px;
$admin-nav-background-color: color("gray","lighten-5");

//Sidebar
$admin-sidebar-width: 250px;
$admin-sidebar-border-color: rgba(255,255,255, 0.2);
$admin-sidebar-hover-color: rgba(0,0,0, 0.2);
$admin-sidebar-element-padding: 10px 20px;
$admin-sidebar-width-hidden: #{$medium-screen};

//Main
$admin-main-background-color: color("gray","lighten-3");

//Slabs
$admin-short-slab-height: 100px;
$admin-slab-background-color: color("shade","white");

//Footer
$admin-footer-height: 45px;
$admin-footer-color:color("gray","lighten-4");
